'use client'; // Error boundaries must be Client Components

import { Inter } from 'next/font/google';
import { MantineProvider } from '@mantine/core';
import { cn } from '@/utils';
import { ErrorPageContent } from '@/components';

const inter = Inter({ subsets: ['latin'], display: 'swap' });

// Note: showErrorInfo cannot be true on ErrorPageContent
// since useSearchParams breaks global-error.tsx

export default function GlobalError({
  error: _error,
  reset: _reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    // global-error must include html and body tags
    <html>
      <body className={cn(inter.className, 'bg-white text-black')}>
        <MantineProvider>
          <ErrorPageContent />
        </MantineProvider>
      </body>
    </html>
  );
}
